import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import CallToAction from "components/CallToAction";
import Section from "components/Section";
import { useHeader } from "hooks";
import { addEditAttributes } from "utils/episerver";

import { Container, Heading, CallToActions, Header, Introduction } from "./PageList.styled";
import PageListProps from "./PageListProps";

const PageList = ({
    identifier,
    theme = "blue",
    contentTheme = "cyan",
    heading,
    introduction,
    displayOption = "Left",
    items,
    anchorName,
}: PageListProps): ReactElement => {
    const headerState = useHeader();
    return (
        <Section theme={theme} id={anchorName} alignItems="center">
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    displayOption: displayOption,
                }}
            >
                <Container key={identifier || `PageListContent`}>
                    {heading && (
                        <Header $headerVisible={headerState.visible}>
                            <Heading
                                as="h2"
                                {...addEditAttributes("Heading")}
                                dangerouslySetInnerHTML={{ __html: heading }}
                            />
                             {introduction && (
                                <Introduction
                                    {...addEditAttributes("Introduction")}
                                    dangerouslySetInnerHTML={{
                                        __html: introduction || "",
                                    }}
                                />
                             )}
                        </Header>
                    )}
                    <CallToActions {...addEditAttributes("Pages")}>
                        {items?.map((item, index) => {
                            return (
                                <CallToAction
                                    key={`CallToAction${index}`}
                                    id={item.identifier}
                                    contentTheme={
                                        theme == "lightgray"
                                            ? "black"
                                            : contentTheme
                                    }
                                    {...item}
                                />
                            );
                        })}
                    </CallToActions>
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(PageList);
